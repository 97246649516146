import React, { useContext } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './Pricing.css';

function Pricing() {
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    const handleRegister = () => {
        navigate('/register');  // Navigate to the Register page
    };

    const handlePayment = (plan) => {
        navigate(`/payment?plan=${plan}`);  // Navigate to the Payment page with plan info
    };

    return (
        <Container className="my-5 pt-5">
            <h1 className="text-center mb-4">Pricing Plans</h1>
            <Row>
                {/* Standard Plan */}
                <Col md={4}>
                    <Card className="h-100 d-flex flex-column text-center">
                        <Card.Body className="flex-grow-1 d-flex flex-column justify-content-between">
                            <div>
                                <Card.Title>Standard</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Free</Card.Subtitle>
                                <Card.Text>
                                    <ul className="list-unstyled" style={{ paddingLeft: '40px' }}>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Basic Feature 1
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Basic Feature 2
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-x-circle-fill text-danger me-2"></i>
                                            Advanced Feature 1
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-x-circle-fill text-danger me-2"></i>
                                            Premium Feature 1
                                        </li>
                                    </ul>
                                </Card.Text>
                            </div>
                            {!isAuthenticated && (
                                <Button variant="primary" onClick={handleRegister}>Sign Up</Button>
                            )}
                        </Card.Body>
                    </Card>
                </Col>

                {/* Professional Plan */}
                <Col md={4}>
                    <Card className="h-100 d-flex flex-column text-center popular-plan">
                        <Card.Body className="flex-grow-1 d-flex flex-column justify-content-between">
                            <div>
                                <Card.Title>Professional</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">$29/month</Card.Subtitle>
                                <Card.Text>
                                    <ul className="list-unstyled" style={{ paddingLeft: '40px' }}>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Basic Feature 1
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Basic Feature 2
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Advanced Feature 1
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-x-circle-fill text-danger me-2"></i>
                                            Premium Feature 1
                                        </li>
                                    </ul>
                                    <strong>14 days free trial</strong>
                                </Card.Text>
                            </div>
                            <Button variant="success" onClick={() => isAuthenticated ? handlePayment('professional') : handleRegister()}>
                                {isAuthenticated ? 'Upgrade Now' : 'Start Free Trial'}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Premium Plan */}
                <Col md={4}>
                    <Card className="h-100 d-flex flex-column text-center">
                        <Card.Body className="flex-grow-1 d-flex flex-column justify-content-between">
                            <div>
                                <Card.Title>Premium</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">$49/month</Card.Subtitle>
                                <Card.Text>
                                    <ul className="list-unstyled" style={{ paddingLeft: '40px' }}>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Basic Feature 1
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Basic Feature 2
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Advanced Feature 1
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                                            Premium Feature 1
                                        </li>
                                    </ul>
                                    <strong>28 days free trial</strong>
                                </Card.Text>
                            </div>
                            <Button variant="warning" onClick={() => isAuthenticated ? handlePayment('premium') : handleRegister()}>
                                {isAuthenticated ? 'Upgrade Now' : 'Start Free Trial'}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Pricing;
