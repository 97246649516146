import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(); // Create a new context

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to manage authentication status
  const [username, setUsername] = useState(''); // State to manage username
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        setLoading(true);
        const response = await axios.get(process.env.REACT_APP_HOME_URL + 'status', { withCredentials: true });
        if (response.data.authenticated) {
          setIsAuthenticated(true);
          setUsername(response.data.username); // Set username from response
        } else {
          setIsAuthenticated(false);
          setUsername('');
        }
      } catch (error) {
        console.error('Error checking authentication status: ', error);
        setIsAuthenticated(false);
        setUsername('');
      } finally {
        setLoading(false);
      }
    };
    checkStatus();
  }, []);

  // Updated login function to accept username as a parameter
  const login = (username) => {
    setIsAuthenticated(true); // Set authentication status to true
    setUsername(username); // Update the username
  };

  const logout = async () => {
      setIsAuthenticated(false);
      setUsername(''); // Clear username
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, username, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
