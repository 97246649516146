import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import  Select  from 'react-select'
import axios from "axios";

const Screener = ({ onSubmit }) =>{
    const [formData, setFormData] = useState({
          earliestDay: '2024-08-01',
          minPrice: 5,
          maxPrice: 30,
          maxPriceGain: 0.1,
          minVolume: 1500000,
          orderby: 'Volume',
          volGain: 1.1,
          sector: 'all',
          industry: 'all',
    })

    const [sectors, setSectors] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);  

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero
      const day = String(today.getDate()).padStart(2, '0'); // Add leading zero
      return `${year}-${month}-${day}`;
    };

    
    useEffect(()=>{
      const fetchSectorsAndIndustries = async ()=>{
        try{
          const[sectorsResponse, industriesResponse] = await Promise.all([
            axios.get(process.env.REACT_APP_HOME_URL+'sectors', {withCredentials: true}),
            axios.get(process.env.REACT_APP_HOME_URL+'industries', {withCredentials: true}),
          ]);

        setSectors(sectorsResponse.data.map(sector => ({ value: sector, label: sector })));
        setIndustries(industriesResponse.data.map(industry => ({ value: industry, label: industry })));

        }catch(err){
          console.error('Error fetching and industries', err);
        }
      }
      fetchSectorsAndIndustries();
    }, [])

    const validateForm = () => {
      const newErrors = {};

      if (!formData.earliestDay) {
        newErrors.earliestDay = 'Earliest Day is required';
      }
      if (!formData.minPrice || parseFloat(formData.minPrice) <= 0) {
        newErrors.minPrice = 'Minimum Price must be greater than 0';
      }
      if (!formData.maxPrice || parseFloat(formData.maxPrice) <= 0 || parseFloat(formData.maxPrice) < parseFloat(formData.minPrice)) {
        newErrors.maxPrice = 'Maximum Price must be greater than Minimum Price';
      }
      if (!formData.maxPriceGain || parseFloat(formData.maxPriceGain) < 0) {
        newErrors.maxPriceGain = 'Maximum Price Gain must be a positive number';
      }
      if (!formData.minVolume || parseFloat(formData.minVolume) <= 0) {
        newErrors.minVolume = 'Minimum Volume must be greater than 0';
      }
      if (!formData.volGain || parseFloat(formData.volGain) < 0) {
        newErrors.volGain = 'Volume Gain must be a positive number';
      }
      if (formData.sector.length === 0) {
        newErrors.sector = 'At least one sector must be selected';
      }
      if (formData.industry.length === 0) {
        newErrors.industry = 'At least one industry must be selected';
      }

      //console.log(formData);
      setErrors(newErrors);

      return Object.keys(newErrors).length === 0;
  };


    const handleChange = (name, seletedOptions) =>{
  
      let value;
    // Handle the case when selection is cleared
    if ((name === 'sector' || name === 'industry') && (seletedOptions.length === 0)) {
      value = ['all']; // Set to 'all' if no options are selected
    } else {
      value = seletedOptions ? seletedOptions.map(option => option.value) : [];
    }

    setFormData({
      ...formData,
      [name]: value,
    });
    }
    const handleSubmit = (e)=>{
      e.preventDefault();
      const formIsValid = validateForm()
      setValidated(true);

      if(formIsValid){
        const finalData = {
          ...formData,
          minPrice: parseFloat(formData.minPrice) || 0, // Default to 0 if not provided
          maxPrice: parseFloat(formData.maxPrice) || Infinity, // Default to Infinity if not provided
          maxPriceGain: parseFloat(formData.maxPriceGain) || 1, // Default to 0 if not provided
          minVolume: parseFloat(formData.minVolume) || 1000000, // Default to 0 if not provided
          volGain: parseFloat(formData.volGain) || 1, // Default to 0 if not provided
          sector: formData.sector.length > 0 ? formData.sector : ['all'], // Default to 'all' if not provided
          industry: formData.industry.length > 0 ? formData.industry : ['all'], // Default to 'all' if not provided
        };
        onSubmit(finalData);
      }
    }

    return(
      <Container className="mt-5 pt-5">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {/* Start Date input*/}
        <Form.Group controlId='earliestDay'>
          <Form.Label>Earliest Day of the Analysis:</Form.Label>
          <Form.Control
            type='date'
            name='earliestDay'
            value={formData.earliestDay}
            onChange={(e) => setFormData({ ...formData, earliestDay: e.target.value })}
            isInvalid={!!errors.earliestDay}
            required
            min="2022-01-01"
            max={getCurrentDate()}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.earliestDay}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Minimum price */}
        <Form.Group controlId='minPrice'>
          <Form.Label>Minimum Price:</Form.Label>
          <Form.Control 
           type='number'
           name='minPrice'
           value={formData.minPrice} 
           onChange={(e) => setFormData({ ...formData, minPrice: e.target.value })}
           placeholder="0"
           isInvalid={!!errors.minPrice}
           min={0.0}
           step="0.01"
          />
          <Form.Control.Feedback type='invalid'>
            {errors.minPrice}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Maximum price */}
        <Form.Group controlId='maxPrice'>
          <Form.Label>Maxmimum Price</Form.Label>
          <Form.Control 
           type='number'
           name='maxPrice'
           value={formData.maxPrice}
           onChange={(e)=>{setFormData({...formData, maxPrice: e.target.value})}}
           isInvalid={!!errors.maxPrice}
           min={0}
           step={0.01}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.maxPrice}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Maximum price gain */}
        <Form.Group controlId="maxPriceGain">
          <Form.Label>Max Price Gain:</Form.Label>
          <Form.Control 
           type='number'
           name='maxPriceGain'
           value={formData.maxPriceGain}
           onChange={(e)=>{setFormData({...formData, maxPriceGain:e.target.value})}}
           placeholder="0.1 'which is 10%'"
           isInvalid = {!!errors.maxPriceGain}
           min={0}
           step="0.01"
          />
          <Form.Control.Feedback type='invalid'>
            {errors.maxPriceGain}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Minimum volume */}
        <Form.Group controlId="minVolume">
          <Form.Label>Minimum Volume</Form.Label>
          <Form.Control 
           type='number'
           name='minVolume'
           value={formData.minVolume}
           onChange={(e)=>{setFormData({...formData, minVolume:e.target.value})}}
           placeholder="1000000"
           isInvalid={!!errors.minVolume}
           min={0}
           step="1"
          />
          <Form.Control.Feedback>
            {errors.minVolume}
          </Form.Control.Feedback>
        </Form.Group>


        {/* Volume gain */}
        <Form.Group controlId="volGain">
          <Form.Label>Volume gain:</Form.Label>
          <Form.Control
           type='number'
           name='volGain'
           value={formData.volGain}
           onChange={(e)=>{setFormData({...formData, volGain:e.target.value})}}
           placeholder="0.5 'which means 50%'"
           min={0}
           step="0.01"
          />
        </Form.Group>

        <Form.Group controlId="orderby">
          <Form.Label>Order By:</Form.Label>
          <Form.Control
            as="select"
            value={formData.orderby}
            onChange={(e) => setFormData({ ...formData, orderby: e.target.value })}
          >
          <option value="current_volume_desc">Current Volume (Descending)</option>
          <option value="current_volume_asc">Current Volume (Ascending)</option>
          <option value="price_gain_desc">Price Gain (Descending)</option>
          <option value="price_gain_asc">Price Gain (Ascending)</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="sector">
          <Form.Label>Sector (Optional):</Form.Label>
          <Select
            options={sectors}
            isMulti
            onChange={(selectedOptions) => handleChange('sector', selectedOptions)}
            value={sectors.filter(option => formData.sector.includes(option.value))}
          />
        </Form.Group>

        <Form.Group controlId="industry">
          <Form.Label>Select Industry (Optional):</Form.Label>
         <Select
            options={industries}
            isMulti
            onChange={(selectedOptions) => handleChange('industry', selectedOptions)}
            value={industries.filter(option => formData.industry.includes(option.value))}
          />
        <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>Submit</Button>
        </Form.Group>
      </Form>
      {Object.keys(errors).length > 0 && (
        <Alert variant="danger" className="mt-3">
          Please correct the errors above and try again.
        </Alert>
      )}
      </Container>
    )

};

export default Screener;