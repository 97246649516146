import React from 'react'
import Container from 'react-bootstrap/Container';

function Features() {
  return (
    <Container style={{ marginTop: '100px' }}> {/* Adjust margin */}
      <div>Features</div>
    </Container>
  )
}

export default Features