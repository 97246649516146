import React from 'react'
import { Container } from 'react-bootstrap'

function About() {
  return (
    <Container style={{ marginTop: '100px' }}> {/* Adjust margin */}
      <div>About</div>
    </Container>
  )
}

export default About