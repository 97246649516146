import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
//import './MyNav.css';

function Sidebar({ isAuthenticated, logout, username, handleClose, className }) { // Accept className
  return (
    <div className={`sidebar ${className}`}> {/* Apply the className */}
      <Nav className="flex-column" onClick={handleClose}>
        <Nav.Link as={NavLink} to='/contact' className="sidebar-link">Contact Us</Nav.Link>
        <Nav.Link as={NavLink} to='/about' className="sidebar-link">About Us</Nav.Link>
        {isAuthenticated && (
          <>
            <Nav.Link as={NavLink} to='/dashboard' className="sidebar-link">
              Dashboard
            </Nav.Link>
            <Nav.Link as={NavLink} to='/pricing' className="sidebar-link">
              Upgrade
            </Nav.Link>
            <Nav.Link as={NavLink} to='/logout' onClick={logout} className="sidebar-link">
              Logout
            </Nav.Link>
          </>
        )}
        {!isAuthenticated && (
          <>
            <Nav.Link as={NavLink} to='/register' className="sidebar-link">Register</Nav.Link>
            <Nav.Link as={NavLink} to='/login' className="sidebar-link">Login</Nav.Link>
          </>
        )}
      </Nav>
    </div>
  );
}

export default Sidebar;
