import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOME_URL}/register`, 
        { username, email, password }, 
        { withCredentials: true }
      );
      console.log("Register message: ", response.data);
      setMessage(response.data.message);
      setAlertVariant("success");
      login();  // Log the user in
      navigate('/');  // Navigate to the home page
    } catch (error) {
      setMessage(error.response?.data?.message || "Registration failed");
      setAlertVariant("danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container style={{ marginTop: '100px' }}> 
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Username" 
              value={username} 
              onChange={e => setUsername(e.target.value)} 
              required 
            />
            <Form.Text className="text-muted">
              Pick a unique username.
            </Form.Text>
          </Form.Group>
          
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control 
              type="email" 
              placeholder="Enter email" 
              value={email} 
              onChange={e => setEmail(e.target.value)} 
              required 
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={e => setPassword(e.target.value)}
              required 
              minLength={6}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group>
          
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
        </Form>
      </Container>

      {message && (
        <Alert variant={alertVariant} className='mt-3'>
          {message}
        </Alert>
      )}
    </>
  );
}

export default Register;
