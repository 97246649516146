import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';

function Logout() {
  const { logout } = useContext(AuthContext); // Access the logout function from AuthContext
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_HOME_URL + "logout", { withCredentials: true });
        setMessage(response.data.message || "You have been logged out successfully.");
        logout(); // Update the context to reflect that the user is logged out
        setLoading(false); // Stop loading after successful logout
        navigate('/'); // Redirect to the homepage after logout
      } catch (error) {
        setMessage(error.response?.data?.message || "An error occurred while logging out.");
        setLoading(false); // Stop loading on error
      }
    };

    performLogout();
  }, [logout, navigate]);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      {loading ? (
        <Spinner animation="border" variant="primary" /> // Display loading spinner while logging out
      ) : (
        <Alert variant='warning'>
          {message}
        </Alert>
      )}
    </div>
  );
}

export default Logout;
