import React from 'react';
import { Container, Button } from 'react-bootstrap';

const Sidebar = ({ onSelectOption }) => {
  return (
    <Container fluid className="d-flex flex-column bg-dark text-white vh-100 p-4" style={{ minWidth: '250px' }}>
      <div className="mt-auto mb-auto">
        <Button
          variant="outline-light"
          className="w-100 mb-3"
          onClick={() => onSelectOption('dailyScreener')}
        >
          Daily Screener
        </Button>
        <Button
          variant="outline-light"
          className="w-100"
          onClick={() => onSelectOption('Tracker')}
          disabled
        >
          Performance Tracker
        </Button>
      </div>
    </Container>
  );
};

export default Sidebar;
