import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';


function Login() {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertVar, setAlertVar] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_HOME_URL+"login", { email, password }, { withCredentials: true });
      setMessage(response.data.message);
      setLoading(false);
      login(response.data.username);
      setAlertVar("success");
      navigate('/');
    } catch (error) { 
      setMessage(error.response?.data?.message || 'An error occurred');
      setLoading(false); 
      setAlertVar("danger");
    }
  }

return (
    <Container style={{ marginTop: '100px' }}> {/* Adjust margin */}
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button type="submit">{ loading ? "Submitting ..." : "Submit"}</Button>
        {message && (
          <Alert variant={alertVar}>
            {message}
          </Alert>
        )}
      </Form>
    </Container>
  );
}

export default Login;
