import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const plan = queryParams.get('plan');  // Retrieve the 'plan' from the query string

    const [amount, setAmount] = useState('');
    const [planName, setPlanName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (plan === 'professional') {
            setAmount('29');
            setPlanName('Professional');
        } else if (plan === 'premium') {
            setAmount('49');
            setPlanName('Premium');
        }
    }, [plan]);

    const handlePayment = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);
        setError('');
        setSuccess('');

        try {
            // Create a payment method
            const cardNumberElement = elements.getElement(CardNumberElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
            });

            if (error) {
                setError(error.message);
                setIsLoading(false);
                return;
            }

            // Send payment method ID to your backend
            const response = await axios.post(process.env.REACT_APP_HOME_URL + "payment", {
                amount: parseInt(amount, 10) * 100,  // Convert to cents
                paymentMethodId: paymentMethod.id,
            });

            if (response.data.success) {
                setSuccess('Payment successful!');
            } else {
                setError('Payment failed. Please try again.');
            }
        } catch (err) {
            setError('Payment failed. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container className="payment-container" style={{ marginTop: '100px', textAlign: 'center' }}>
            <h2 className="mb-4">Upgrade to {planName}</h2>  {/* Dynamic Heading */}
            <h4 className="mb-4">Amount: ${amount}</h4>  {/* Display the amount */}
            <Row className="justify-content-center">
                <Col md={6}>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <Form onSubmit={handlePayment}>
                        <Form.Group className="mb-3">
                            <Form.Label>Card Number</Form.Label>
                            <CardNumberElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                                className="form-control"
                            />
                        </Form.Group>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Expiration Date</Form.Label>
                                    <CardExpiryElement
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#424770',
                                                    '::placeholder': {
                                                        color: '#aab7c4',
                                                    },
                                                },
                                                invalid: {
                                                    color: '#9e2146',
                                                },
                                            },
                                        }}
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>CVC</Form.Label>
                                    <CardCvcElement
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#424770',
                                                    '::placeholder': {
                                                        color: '#aab7c4',
                                                    },
                                                },
                                                invalid: {
                                                    color: '#9e2146',
                                                },
                                            },
                                        }}
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Button variant="primary" className="btn-lg btn-block" type="submit" disabled={!stripe || isLoading}>
                            {isLoading ? 'Processing...' : `Pay $${amount} Now`}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

const Payment = () => (
    <Elements stripe={stripePromise}>
        <PaymentForm />
    </Elements>
);

export default Payment;
