import React, { useContext, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Dropdown, Button } from 'react-bootstrap';
import Sidebar from './MyNavm';  
import './MyNav.css';

function MyNav() {
  const { isAuthenticated, logout, loading, username } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);


const handleClose = () => setExpanded(false);

  return (
    <>
      <Navbar collapseOnSelect fixed="top" expand="lg" className="navbar-dark custom-navbar" expanded={expanded}>
        <Container>
          <Navbar.Brand as={NavLink} to='/'>
            <img src="/images/test.png" alt="logo" className="navbar-brand-img" />
            Stoqzee
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
            {!loading && (
              <Nav className="mx-auto" onClick={handleClose}>
                <Nav.Link as={NavLink} to='/contact' className={({ isActive }) => isActive ? "active-link" : ""}>Contact Us</Nav.Link>
                <Nav.Link as={NavLink} to='/about' className={({ isActive }) => isActive ? "active-link" : ""}>About Us</Nav.Link>
                <Nav.Link as={NavLink} to='/pricing' className={({ isActive }) => isActive ? "active-link" : ""}>Pricing</Nav.Link>
              </Nav>
            )}

            {/* User Dropdown for Authenticated Users */}
            {isAuthenticated && !loading && !expanded && (
              <Nav className="ml-auto d-flex align-items-center">
                <Dropdown align="end">
                  <Dropdown.Toggle variant="link" id="dropdown-user" className="d-flex align-items-center username-dropdown-toggle">
                    <i className="bi bi-person-fill"></i>
                    <span className="ml-2">{username || "User"}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to="/dashboard">Dashboard</Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/pricing">Upgrade</Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/logout" onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            )}

            {/* Log in and Sign up for Unauthenticated Users */}
            {!isAuthenticated && !loading && (
              <Nav className="ml-auto">
                <Nav.Link as={NavLink} to='/login'>Log in</Nav.Link>
                <Nav.Link as={NavLink} to='/register'>
                  <Button variant="outline-light" className="rounded-pill">Sign up</Button>
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Sidebar */}
      {expanded && (
        <>
          <Sidebar
            isAuthenticated={isAuthenticated}
            logout={logout}
            username={username}
            handleClose={handleClose}
            className={`sidebar ${expanded ? 'sidebar-visible' : ''}`} // Apply classes here
          />
          <div className="content-overlay" onClick={handleClose}></div>
        </>
      )}

      <div className={expanded ? "main-content sidebar-open" : "main-content"}>
        {/* Main content goes here */}
      </div>
    </>
  );
}

export default MyNav;
