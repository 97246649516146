import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Spinner, Alert, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOME_URL, { withCredentials: true })
      .then(response => {
        setMessage(response.data.message);
        if (response.data.username) {
          setUsername(response.data.username);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the message!', error);
        setError('Failed to load data. Please try again later.');
      })
      .finally(() => setLoading(false));
  }, []);

  const handleButtonClick = (plan) => {
    if (username) {
      navigate(`/payment?plan=${plan}`);  // Navigate to the payment page with plan info
    } else {
      navigate('/register');  // Navigate to the Register page
    }
  };

  return (
    <div>
      {/* Hero Section */}
      <Container fluid style={{ backgroundColor : 'black', padding: '200px 0', textAlign: 'center' }}>
        {loading ? (
          <Spinner animation="border" variant="light" />
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <>
            <h1 style={{ color: '#F8F9FA', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)', fontWeight: 'bold' }}>
              {username ? `Welcome back, ${username}!` : message || "Welcome to home"}
            </h1>
            {!username && (
              <Button variant="primary" size="lg" onClick={() => navigate('/register')}>
                Get Started
              </Button>
            )}
          </>
        )}
      </Container>

      {/* Features Section */}
      <Container className="my-5">
        <Row>
          <Col md={4}>
            <Card className="h-100 d-flex flex-column">
              <Card.Img 
                variant="top" 
                src="/images/card-selector.png" 
                style={{ height: '200px', objectFit: 'cover' }} 
              />
              <Card.Body className="d-flex flex-column flex-grow-1">
                <div className="d-flex justify-content-between mb-3">
                  <h4 style={{ marginRight: '10px' }}>Stock Selector</h4>
                  <p>The technology you need to trade easy.</p>
                </div>
                {!username && (
                  <Button 
                    variant="primary" 
                    className="mt-auto"  
                    onClick={() => handleButtonClick('standard')}
                  >
                    Free Now
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100 d-flex flex-column">
              <Card.Img 
                variant="top" 
                src="/images/card-dashboard.png" 
                style={{ height: '200px', objectFit: 'cover' }} 
              />
              <Card.Body className="d-flex flex-column flex-grow-1">
                <div className="d-flex justify-content-between mb-3">
                  <h4 style={{ marginRight: '10px' }}>Dashboard</h4>
                  <p>Empower your trading decision with the most updated data in the market.</p>
                </div>
                <Button 
                  variant="primary" 
                  className="mt-auto"  
                  onClick={() => handleButtonClick('professional')}
                >
                  {username ? 'For $29' : 'For $29'}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100 d-flex flex-column">
              <Card.Img 
                variant="top" 
                src="/images/card-strategy.png" 
                style={{ height: '200px', objectFit: 'cover' }} 
              />
              <Card.Body className="d-flex flex-column flex-grow-1">
                <div className="d-flex justify-content-between mb-3">
                  <h4 style={{ marginRight: '10px' }}>Auto Strategy</h4>
                  <p>Automate your strategy and make money while doing other things in life.</p>
                </div>
                <Button 
                  variant="primary" 
                  className="mt-auto"  
                  onClick={() => handleButtonClick('premium')}
                >
                  {username ? 'For $49' : 'For $49'}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Footer */}
      <footer className="bg-dark text-white text-center py-3">
        <p>&copy; 2024 MyApp. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
