import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
function Contact() {
  return (
    <Container style={{ marginTop: '100px' }}>
      <Row>
        {/* Contact Information */}
        <Col md={5}>
          <h2>Check Our Contact</h2>
          <div className="contact-info">
            <div className="mb-4">
              <i className="bi bi-geo-alt-fill"></i>
              <h5>Address</h5>
              <p>A108 Adam Street, New York, NY 535022</p>
            </div>
            <div className="mb-4">
              <i className="bi bi-telephone-fill"></i>
              <h5>Call Us</h5>
              <p>+1 5589 55488 55</p>
            </div>
            <div className="mb-4">
              <i className="bi bi-envelope-fill"></i>
              <h5>Email Us</h5>
              <p>info@example.com</p>
            </div>
          </div>
        </Col>

        {/* Contact Form */}
        <Col md={7}>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Your Name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Control type="email" placeholder="Your Email" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Subject" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control as="textarea" rows={4} placeholder="Message" />
            </Form.Group>
            <Button variant="success" size="lg">Send Message</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;