import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './Components/Home'
import About from './Components/About'
import Features from './Components/Features'
import Contact from './Components/Contact'
import SDashboard from './Components/SDashboard'
import Register from './Components/Register'
import MyNav from './Components/MyNav'
import Login from './Components/Login'
import Logout from './Components/Logout'
import Payment from './Components/Payment'
import Pricing from './Components/Pricing'
import { AuthProvider } from './context/AuthContext'


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <AuthProvider>
      <Router>
        <MyNav />
        <Routes>
          <Route path='/' Component={Home}></Route>
          <Route path='/about' Component={About}></Route>
          <Route path='/register' Component={Register}></Route>
          <Route path='/contact' Component={Contact}></Route>
          <Route path='/features' Component={Features}></Route>
          <Route path='/login' Component={Login}></Route>
          <Route path='/logout' Component={Logout}></Route>
          {/* <Route path='/dashboard' Component={Dashboard}></Route> */}
          <Route path='/dashboard' Component={SDashboard}></Route>
          <Route path='/payment' Component={Payment}></Route>
          <Route path='/pricing' Component={Pricing}></Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
