import React, { useState} from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import Screener from './Screener';
import { Container, Row, Col, Table, Pagination } from 'react-bootstrap';

function SDashboard() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [stocks, setStocks] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10); // Rows per page limit
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [formData, setFormData] = useState({
    earliestDay: '01-08-2024',
    minPrice: 5,
    maxPrice: 30,
    maxPriceGain: 0.1,
    minVolume: 1500000,
    volGain: 1.1,
    sector: 'all',
    industry: 'all',
  });

  const fetchStocks = async (pageNumber = 1, finalData = {}) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOME_URL}screener`,
        { ...formData, ...finalData, page: pageNumber, perPage: rowsPerPage },
        { withCredentials: true }
      );
      const newStocks = response.data.stocks;
      setStocks(newStocks);
      setTotalPages(Math.ceil(response.data.totalRecords / rowsPerPage)); // Calculate total pages
      setPage(pageNumber); // Update the current page
    } catch (err) {
      console.error('Error fetching stocks', err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchStocks(newPage); // Fetch data for the new page
    }
  };

  const handleSubmitForm = (formData) => {
    setFormData({ ...formData });
    setShowTable(true);
    fetchStocks(1, formData); // Fetch the first page of data when the form is submitted
  };

  return (
    <Container fluid style={{ height: '100vh' }}>
      <Row className="h-100">
        <Col md={3} className="bg-dark p-0">
          <Sidebar onSelectOption={setSelectedOption} />
        </Col>
        <Col md={9} className="d-flex flex-column align-items-center justify-content-center">
          {selectedOption === 'dailyScreener' && (
            <div className="w-75">
              <Screener onSubmit={handleSubmitForm} />
            </div>
          )}

          {/* Conditionally render the table */}
          {showTable && (
            <>
              <div className="table-responsive mt-3 w-100">
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Stock</th>
                      <th>Sector</th>
                      <th>Industry</th>
                      <th>Max Price Gain</th>
                      <th>Price Gain (Now vs Earliest)</th>
                      <th>Max Volume Gain</th>
                      <th>Volume Gain (Now vs Earliest)</th>
                      <th>Current Volume</th>
                      <th>Current Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stocks.map((stock, index) => (
                      <tr key={index}>
                        <td>{stock.stock}</td>
                        <td>{stock.sector}</td>
                        <td>{stock.industry}</td>
                        <td>{stock.maxPriceGainSinceEarliestDate}</td>
                        <td>{stock.PriceGainbetweenNowandEarliestDate}</td>
                        <td>{stock.maxVolGainSinceEarliestDate}</td>
                        <td>{stock.VolGainbetweenNowandEarliestDate}</td>
                        <td>{stock.currentVol}</td>
                        <td>{stock.currentPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {loading && <p>Loading...</p>}

              {/* Pagination Controls */}
              <Pagination className="justify-content-center mt-3">
                <Pagination.Prev
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1 || loading}
                />
                <Pagination.Item active>{page}</Pagination.Item>
                <Pagination.Next
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages || loading}
                />
              </Pagination>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default SDashboard;
